import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const CoC = () => {
    return( 
        <div id="coc">
            <Container>
                <Row className="bgPanel">
                    <Col>
                        <h1> CODE OF CONDUCT </h1>
                        <hr/>
                            <div className="tacStyle">
                                <b>Definitions</b>
                                <p/>
                                A Drop of Delight exists to raise funds towards the projects by the nonprofit organization Just A Drop. Our mission is best served in a community of respect, safety and acceptance with an environment free from harassment or intimidation. As a result, certain actions and behaviors will not be tolerated. 
                                <p/>
                                <b>Basics</b>
                                <br/>
                                <ul>
                                    <li> Be respectful. </li>
                                    <li> Contact us for help at: contact@adropofdelight.org </li>
                                    <li> Abusive behavior is never tolerated. </li>
                                    <li> A Drop of Delight reserves the right to review and remove any data published to adropofdelight.org and related content. </li>
                                    <li> Violations of this code may result in automatic permanent expulsion from the A Drop of Delight community. </li>
                                </ul>
                                <p/>
                                <b>General</b>
                                <p/>
                                We, at A Drop of Delight, expect all members in our community, including staff, participants, partners, viewers and donors, to abide by this Code of Conduct at all times in all community events and streams, online and in-person, as well as in communications pertaining to all ADoD actions. 
                                <br/>This policy covers usage of the ADoD donation tracker, website, events and all other services offered and provided by A Drop of Delight. Additionally, this policy applies to all community behaviors, including but not limited to streams, social media, Twitch. 
                                <br/>This Code of Conduct acts in accordance with, and in no way nullifies or invalidates, all other terms and conditions related to A Drop of Delight. 
                                <br/>All definitions of subjective terms such as “hateful,” “discriminatory,” and “non inclusive” will be decided at the sole discretion of our team at A Drop of Delight. 
                                <p/>
                                <b>Zero Tolerance Harassment Policy</b>
                                <p/>
                                We are committed to providing a friendly, safe and welcoming environment for all, regardless of gender identity, sexual orientation, ability, ethnicity, religion, age, physical appearance, body size, race, or similar personal characteristics.
                                <p/>
                                We ask that you please respect the opinions of others regarding differences in tactics, specific preferences, ideologies, and cultures.  Differences in opinions are not reasons for rude behavior, and such actions will bring subsequent consequences. 
                                <p/>
                                Any spamming, trolling, flaming, baiting, or other attention-stealing behavior is not welcome, and will not be tolerated.
                                <p/>
                                Harassing other viewers, donors, participants, volunteers, or staff of the Event is never tolerated, whether via public or private media.
                                <p/>
                                Avoid using offensive or harassing language that might detract from a friendly, safe, and welcoming environment for all.
                                <p/>
                                Harassment includes, but is not limited to: harmful or prejudicial verbal or written comments related to gender identity, sexual orientation, ability, ethnicity, religion, age, physical appearance, body size, race, or similar personal characteristics; inappropriate use of nudity, sexual images, and/or sexually explicit language in public spaces; threats of physical or non-physical harm; deliberate intimidation, stalking or following; harassing photography or recording; sustained disruption of events; inappropriate physical contact; and unwelcome sexual attention.
                                <p/>
                                <b>Reporting Violations to this Code of Conduct</b>
                                <p/>
                                If you believe someone is harassing you or demonstrating some other form of inappropriate behavior, please let us know at contact@adropofdelight.org. If this is the first report of a problem, please include as much detail as possible, so as to make it easier to address such issues with more context. 
                                <p/>
                                <b>Consequences of Unacceptable Behavior</b>
                                <p/>
                                All content published to and created by A Drop of Delight is monitored at the sole discretion of A Drop of Delight administrators. 
                                <p/>
                                Unacceptable behavior from any community member including staff, donors, volunteers, sponsors and any others, including those in authority, will not be tolerated. 
                                <p/>
                                Anyone asked to cease unacceptable behavior is expected to comply immediately. 
                                <p/>
                                If a community member engages in unacceptable behavior, A Drop of Delight administrators may take any appropriate actions, including temporary bans or permanent expulsions from the community without warning nor refund, if applicable. 
                                <p/>
                                <b>Addressing Unfair Reports</b>
                                <p/>
                                If you feel you have been falsely accused of violating this Code of Conduct, you may file your grievance with A Drop of Delight through contact as listed below. We will do our best to ensure appropriate actions taken towards what we judge as the best way of fostering a friendly and safe community. 
                                <p/>
                                <b>Changes to our Code of Conduct</b>
                                <p/>
                                This is a living document and may be updated as needed.
                                <p/>
                                <b>Contact Information</b>
                                <p/>
                                Please contact us at contact@adropofdelight.org to report any problems or address any abuse report grievances. 
                                <p/>
                                It is also encouraged to contact us if you are curious about possible borderline offenses in regards to their appropriateness in our community. We are happy to provide guidance and be of assistance to your success as part of our community. 
                                <p/>
                                <b>Credit</b>
                                <p/>
                                This Code of Conduct is largely based on the use of Code of Conduct from <a className="styledLink" href="https://tipofthehats.org/" target="_blank" rel="noopener noreferrer">https://tipofthehats.org/</a> as an outline. Thanks to Jason “whisker” Baxter for the help. 
                            </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CoC;